/* stylelint-disable no-unknown-animations */

.program-announcement-item {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        height: 100%;
        //opacity: 0;
        transition: opacity $trs--base;

        .program-announcement-item--time,
        .program-announcement-item--title {
            padding-right: 22px;
        }

        .program-announcement-item--time {
            @include ff--base-heavy;
            font-size: 7.2rem;
            line-height: 1;
            display: inline-block;
            margin-bottom: 48px;
        }

        .program-announcement-item--title {
            display: inline-block;
            width: 100%;

            @include ff--base-heavy;
        }

        .program-announcement-item--arrow {
            opacity: 0;
        }
    }

    // Facets
    & {
        // Unary
        &.program-announcement-item__unary {
            //
        }

        // Dual
        &.program-announcement-item__dual {
            .program-announcement-item--text-container {
                overflow: hidden;
                margin-left: $sz--dual-lateral-margin;
            }
        }

        // Title size levels
        & {
            .program-announcement-item--title {
                margin-bottom: 48px;
                line-height: $sz--big-titles-lh;

                @include create-modular-scale($modular-scale--title-sizes);
            }
        }

        // Settled title
        & {
            &.program-announcement-item__has-settled-title-size {
                opacity: 1;
                animation: lateral-enter-text-transition $trs--highlights;
            }

            &.program-announcement-item__leave-transition {
                animation: lateral-leave-text-transition $trs--highlights;

                .program-announcement-item--arrow {
                    visibility: hidden;
                }
            }
        }

        // Show arrow
        & {
            &.program-announcement-item__show-arrow {
                .program-announcement-item--arrow {
                    opacity: 1;
                }
            }
        }
    }

    // States
}
