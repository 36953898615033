.observer-wrapper {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 100%;
        height: 100%;

        .observer-wrapper--observers {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    // Facets

    // States
}
