.feed-display {
    // Vars

    // Support

    // Module
    & {
        @include grid--root(());
        @include grid--space((gutter: 44px, edge: 44px));

        flex: 1 0 auto;
        height: 100%;
        overflow: hidden;

        .feed-display--state,
        .feed-display--container,
        .feed-display--row,
        .feed-display--col {
            height: 100%;
        }

        .feed-display--state {
            width: 100%;
        }

        .feed-display--col {
            overflow: hidden;
        }
    }

    // Facets

    // States
}
