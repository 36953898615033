@mixin display-warning() {
    content: 'The following view was not intended for the current viewport!';
    color: $c--signal-error;
    background-color: $c--monochrome-black;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    padding: 1.2rem 2.4rem;
    font-size: 4.0rem;
}
