.daily-program-list {
    // Vars

    // Support

    // Module
    & {
        width: 100%;
        height: 100%;

        .daily-program-list--event-list-wrap {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            height: 100%;
        }

        .daily-program-list--event-list-items {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            height: 100%;
            padding: 24px 0;
            overflow: hidden;
        }

        .daily-program-list--event-list-item {
            width: 100%;
        }
    }

    // Facets

    // States
}
