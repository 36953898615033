/// Font-styles, usable for project-wide styling of text instances
///
// TODO: Define base-stack and style properties
@mixin ff--base-regular($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--normal;
    font-style: normal;
}

@mixin ff--base-heavy($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--heavy;
    font-style: normal;
}
