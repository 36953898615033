/* stylelint-disable color-named */

.program-announcement-splash {
    // Vars

    // Support

    @keyframes splash-glitch {
        0% {
            transform: skewX(1deg);
            text-shadow: 0 0 0 rgba(cyan, 0.4), 0 0 0 rgba(red, 0.5), 0 0 0 rgba(white, 0.3);
        }

        2% {
            transform: skewX(0);
            text-shadow: 0 20px 0 rgba(cyan, 0.4), -20px -2px 0 rgba(red, 0.5), 0 -13px 0 rgba(white, 0.3);
        }

        5% {
            text-shadow: -20px -2px 0 rgba(cyan, 0.4), 0 20px 0 rgba(red, 0.5), 0 0 0 rgba(white, 0.3);
        }

        7% {
            transform: skewX(-1deg);
            text-shadow: 0 20px 0 rgba(cyan, 0.4), -20px -2px 0 rgba(red, 0.5), 3px 3px 0 rgba(white, 0.8);
        }

        9% {
            transform: skewX(0);
            text-shadow: -30px 0 0 rgba(cyan, 0.4), 30px 0 0 rgba(red, 0.5), 0 0 0 rgba(white, 0.3);
        }

        12% {
            text-shadow: -10px -10px 0 rgba(cyan, 0.4), 10px 20px 0 rgba(red, 0.5), 0 0 0 rgba(white, 0.3);
        }

        14% {
            text-shadow: 0 20px 0 rgba(cyan, 0), -20px -2px 0 rgba(red, 0), 0 0 0 rgba(white, 0.3);
        }

        15% {
            transform: skewX(0);
            text-shadow: none;
        }

        70% {
            transform: skewX(0);
            text-shadow: none;
        }

        71% {
            transform: skewX(-1deg);
            text-shadow: -20px -2px 0 rgba(cyan, 0.4), 0 20px 0 rgba(red, 0.5), 0 5px 0 rgba(white, 0.3);
        }

        73% {
            transform: skewX(1deg);
            text-shadow: 0 20px 0 rgba(cyan, 0.4), -20px -2px 0 rgba(red, 0.5), 0 0 0 rgba(white, 0.3);
        }

        74% {
            transform: skewX(0);
            text-shadow: none;
        }

        82% {
            transform: skewX(0);
            text-shadow: none;
        }

        83% {
            transform: skewX(1deg);
            text-shadow: -20px -2px 0 rgba(cyan, 0), 0 20px 0 rgba(red, 0), 0 0 0 rgba(white, 0.3);
        }

        86% {
            transform: skewX(-1deg);
            text-shadow: -20px -2px 0 rgba(cyan, 0.4), 0 20px 0 rgba(red, 0.5), 0 5px 0 rgba(white, 0.3);
        }


        87% {
            transform: skewX(0);
            text-shadow: none;
        }

        100% {
            transform: skewX(0);
            text-shadow: none;
        }
    }

    @keyframes slpash-letter-spacing-big {
        0% {
            letter-spacing: 0;
        }

        100% {
            letter-spacing: -160px;
        }
    }

    @keyframes slpash-letter-spacing-small {
        0% {
            letter-spacing: 0;
        }

        100% {
            letter-spacing: -100px;
        }
    }

    // Module
    & {
        position: relative;
        display: flex;
        text-align: center;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        height: 100%;
        opacity: 0;
        transition: opacity $trs--base;

        .program-announcement-splash--observer {
            overflow: visible;
        }

        .program-announcement-splash--letter {
            display: inline-block;
            transition: transform 400ms 1000ms ease;

            &.program-announcement-splash--letter:nth-child(1) {
                transform: translateX(40px);
            }
        }


        .program-announcement-splash--title {
            position: relative;
            display: flex;
            justify-content: center;

            @include ff--base-heavy;

            // Custom positioning to center the text and maximize size for size settlement
            @include mq('wuxga2') {
                position: relative;
                display: block;
                transform: translateY(5%);
                line-height: 0.7;
            }
        }
    }

    // Facets
    & {
        // Title size levels
        & {
            .program-announcement-splash--title {
                @include create-modular-scale($modular-scale--title-sizes);
            }
        }

        // Settled title
        & {
            &.program-announcement-splash__has-settled-title-size {
                opacity: 1;

                .program-announcement-splash--observer {
                    height: auto;
                    overflow: visible;
                }

                &.program-announcement-splash__slide {
                    // nothing special
                }

                &.program-announcement-splash__bounce {
                    // nothing special
                }

                &.program-announcement-splash__glitch {
                    animation: splash-glitch 1000ms 500ms ease infinite;
                }

                &.program-announcement-splash__leave-transition {
                    opacity: 0;
                }
            }
        }
    }

    // States
}
