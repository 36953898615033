.rotation-debug-bar {
    // Vars

    // Support

    // Module
    & {
        z-index: 999;
        position: fixed;
        bottom: 0;
        left: 0;

        .rotation-debug-bar--btn {
            &.rotation-debug-bar--btn__active {
                color: $c--monochrome-black;
                background-color: $c--monochrome-white;
            }
        }
    }

    // Facets

    // States
}
