.mock {
    // Vars

    // Support

    // Module
    & {
        background-color: $c--monochrome-white;
        padding: 40px 80px;
    }

    // Facets

    // States
}
