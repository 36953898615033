////
/// Content types (p)
////

@mixin type--copy {
    font-size: $fz--medium;
    line-height: $lh--medium;
}

@mixin type--daily-program {
    @include ff--base-heavy;
    font-size: 4.5rem;
    line-height: 1.2;
}

@mixin type--program-feature {
    @include ff--base-heavy;
    font-size: 7.2rem;
    line-height: 1.2;
}
