/// Monochrome special decoration colors
///
$c--monochrome-white: #ffffff;
$c--monochrome-black: #000000;
$c--monochrome-black-gray: #222222;
$c--monochrome-dark-gray: #666666;
$c--monochrome-brown-gray: #999999;
$c--monochrome-silver: #bfbfbf;
$c--monochrome-light-gray: #eeeeee;
$c--monochrome-paper-white: #fbfbfb;

$monochromes: (
    'white': $c--monochrome-white,
    'black': $c--monochrome-black,
    'black-gray': $c--monochrome-black-gray,
    'dark-gray': $c--monochrome-dark-gray,
    'brown-gray': $c--monochrome-brown-gray,
    'silver': $c--monochrome-silver,
    'light-gray': $c--monochrome-light-gray,
    'paper-white': $c--monochrome-paper-white,
);
/// Brand specific colors
///
$c--brand-salmon: #fd8181;
$c--brand-dodger-blue: #33a6fb;

$brands: (
    'salmon': $c--brand-salmon,
    'dodger-blue': $c--brand-dodger-blue,
);


/// Single purpose colors
///
$c--label-text: #ffffff;
$c--label-link: #0071b0;
$c--label-selection: null;

$labels: (
    'text': $c--label-text,
    'link': $c--label-link,
    'selection': $c--label-selection,
);


/// Signal colors
///
$c--signal-info: #0000ff;
$c--signal-error: #ff0000;
$c--signal-warning: #ffff00;
$c--signal-success: #008000;

$signals: (
    'info': $c--signal-info,
    'error': $c--signal-error,
    'warning': $c--signal-warning,
    'success': $c--signal-success,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    label: $labels,
    signal: $signals,
);
