// stylelint-disable property-no-vendor-prefix, no-duplicate-selectors, selector-max-universal, selector-no-qualifying-type, plugin/selector-bem-pattern

////
/// Project specific styles
////
html, body {
    background-color: $c--monochrome-black;
    overflow: hidden;
    hyphens: none;
    overflow-wrap: break-word; // We've tried using `overflow-wrap: normal` using `IntersectionObserver` but it failed.
}
