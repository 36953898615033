.animated-mask {
    // Vars

    // Support

    // Module
    & {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 150%;
        height: 100%;
        background: $c--monochrome-black;
        transform: translate(-150%, 0) skewX(-27deg);
        transition: transform $trs--highlights;
    }

    // Facets

    & {
        &.animated-mask__is-visible {
            transform: translate(-25%, 0) skewX(-27deg);
        }
    }

    // States
}
