@keyframes lateral-enter-text-transition {
    from {
        transform: translate(-5vw, 0);
    }

    to {
        transform: translate(0, 0);
    }
}

@keyframes lateral-leave-text-transition {
    from {
        transform: translate(0, 0);
    }

    to {
        transform: translate(-5vw, 0);
    }
}
