/* stylelint-disable no-unknown-animations */

.program-highlight-item {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        height: 100%;
        opacity: 0;
        transition: opacity $trs--base;

        .program-highlight-item--subtitle,
        .program-highlight-item--info,
        .program-highlight-item--location {
            opacity: 0;
            transition: opacity $trs--highlights;
        }

        .program-highlight-item--title {
            display: inline-block;
            line-height: $sz--big-titles-lh;
            margin-bottom: 48px;

            @include create-modular-scale($modular-scale--title-sizes);
            @include ff--base-heavy;

            @include mq('wuxga2') {
                @include create-modular-scale($modular-scale--title-sizes, 'right');
            }
        }

        .program-highlight-item--subtitle,
        .program-highlight-item--info {
            display: block;
            transition-delay: $trs--highlights-speed / 2;

            @include type--program-feature;
        }

        .program-highlight-item--location {
            @include type--program-feature;
            display: block;
            color: $c--monochrome-brown-gray;
            transition-delay: $trs--highlights-speed / 2;
        }
    }

    // Facets
    & {
        // Dual
        &.program-highlight-item__dual {
            text-align: right;
        }

        // Unary
        &.program-highlight-item__unary {
            text-align: left;
        }

        // Settled title
        &.program-highlight-item__has-settled-title-size {
            opacity: 1;
            animation: lateral-enter-text-transition $trs--highlights;

            .program-highlight-item--subtitle,
            .program-highlight-item--info,
            .program-highlight-item--location {
                opacity: 1;
            }
        }

        &.program-highlight-item__leave-transition {
            animation: lateral-leave-text-transition $trs--highlights;
        }
    }

    // States
}
