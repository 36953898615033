////
/// Header styles (hX)
////

@mixin type--h1 {
    @include ff--base-heavy;
    font-size: 7.2rem;
    line-height: 1.19;
}

@mixin type--h2 {
    @include ff--base-regular;
    font-size: 2.8rem;
    line-height: 1.2;
}

@mixin type--h3 {
    @include ff--base-regular;
    font-size: 2.2rem;
    line-height: 1.5;
}

@mixin type--h4 {
    @include ff--base-regular;
    font-size: 1.8rem;
}

@mixin type--h5 {
    @include ff--base-regular;
    font-size: $fz--medium;
}

@mixin type--h6 {
    @include ff--base-regular;
    font-size: $fz--medium;
}
