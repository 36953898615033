.event-list {
    // Vars
    $trs-duration: 400ms;
    $easing-fn: cubic-bezier(0.215, 0.61, 0.355, 1);

    // Support
    @mixin initial-item-state() {
        opacity: 0;
        transform: translate(0, 20px);
    }

    @keyframes event-list--item {
        from {
            @include initial-item-state;
        }

        to {
            transform: translate(0, 0);
            opacity: 1;
        }
    }

    // Module
    & {
        height: 100%;
        overflow: hidden;
        opacity: 0;
        transition: opacity $trs--base;

        .event-list--items {
            padding-top: 44px;
        }

        .event-list--item ~ .event-list--item {
            padding-top: 44px;
        }
    }

    // Facets
    & {
        // Show after list is settled
        &.event-list__has-settled-items {
            opacity: 1;

            .event-list--item {
                animation: event-list--item $trs-duration $easing-fn forwards;

                @include initial-item-state;
            }

            .event-list--header {
                animation: event-list--item $trs-duration $easing-fn forwards;

                @include initial-item-state;
            }
        }

        // Continuing lists
        & {
            .event-list--header {
                &.event-list--header__is-continuing-list {
                    animation: none;
                    opacity: 0;
                }
            }
        }
    }

    // States
}
