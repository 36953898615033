/// Medium
///
@mixin btn--size-medium {
    padding: 11px 16px 9px;
    font-size: $fz--medium;
}

/// Small
///
@mixin btn--size-small {
    padding: 9px 10px 7px;
    font-size: $fz--small;
}

/// Medium bubble
///
@mixin btn--size-bubble-medium {
    padding: 8px;
}

/// Small bubble
///
@mixin btn--size-bubble-small {
    padding: 4px;
}
