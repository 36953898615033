.mock-button {
    // Vars

    // Support

    // Module
    & {
        .mock-button--response {
            margin-top: 20px;
        }
    }

    // Facets

    // States
}
