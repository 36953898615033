.daily-program-overview {
    // Vars
    $num--max-columns: 4;

    // Support

    // Module
    & {
        @include grid--root((row: 'event-list-items', cols: ('event-list-item')));
        @include grid--space((row: 'event-list-items', cols: ('event-list-item'), gutter: 44px, edge: 44px));
        width: 100%;
        height: 100%;

        .daily-program-overview--event-list-wrap {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            height: 100%;
        }

        .daily-program-overview--event-list-items {
            display: flex;
            flex: 0 1 100%;
            height: 100%;
            flex-flow: row nowrap;
            padding: 24px 0;
            overflow: hidden;
        }

        .daily-program-overview--event-list-side {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            height: 100%;
            overflow: hidden;
        }
    }

    // Facets
    & {
        // 4-col module
        &.daily-program-overview__dual {
            .daily-program-overview--event-list-side {
                flex: 0 1 50%;
                overflow: hidden;
            }

            .daily-program-overview--event-list-item {
                flex: 0 1 50%;
                width: 50%;
            }

            @include mq('wuxga2') {
                .daily-program-overview--event-list-wrap {
                    justify-content: space-between;
                }

                .daily-program-overview--event-list-side {
                    &:nth-child(1) {
                        justify-content: flex-start;
                    }

                    &:nth-child(2) {
                        justify-content: flex-end;
                    }
                }

                .daily-program-overview--event-list-item {
                    flex: 0 0 $daily-program-overview--event-list-item-width-wuxga2;
                }
            }
        }

        // 2-col module
        &.daily-program-overview__unary {
            .daily-program-overview--event-list-side {
                flex: 0 1 100%;
            }

            .daily-program-overview--event-list-item {
                flex: 0 1 100%;
                width: 100%;
            }

            @include mq('wuxga1') {
                //.daily-program-overview--event-list-wrap {
                //    justify-content: space-between;
                //}

                .daily-program-overview--event-list-side {
                    &:nth-child(1) {
                        //justify-content: flex-start;
                    }

                    &:nth-child(2) {
                        display: none;
                    }
                }

                //.daily-program-overview--event-list-item {
                //    flex: 0 0 $daily-program-overview--event-list-item-width-wuxga2;
                //}
            }
        }
    }

    // States
}
