.program-announcement-arrow {
    // Vars
    // all these variables are only needed because IE is to stupid to compute the SVG properties
    $up-left-arrow-height: 325px;
    $straight-right-arrow-height: 282px;
    $down-right-arrow-height: 330px;
    $up-left-arrow-width: 1561px;

    // Support

    // Module
    & {
        @include grid--root;
        @include grid--space((gutter: 22px, edge: 0));

        .program-announcement-arrow--row {
            flex-flow: row nowrap;
        }

        .program-announcement-arrow--col {
            position: static;
            width: 100%;
        }

        .program-announcement-arrow--info {
            position: relative;
            display: inline-block;
            margin-bottom: 24px;
            opacity: 0;
            transition: opacity $trs--base;
        }

        .program-announcement-arrow--subtitle {
            @include type--program-feature;
        }

        .program-announcement-arrow--type {
            @include type--program-feature;
            color: $c--monochrome-brown-gray;
        }

        .program-announcement-arrow--location {
            @include type--program-feature;
        }

        .program-announcement-arrow--arrow-container {
            //background-color: rgba($c--brand-salmon, 0.2); // Debug helper
        }

        .program-announcement-arrow--arrow-positioner {
            opacity: 0;
            position: fixed;
            transition: opacity $trs--base;
            //background-color: rgba($c--brand-dodger-blue, 0.2); // Debug helper
        }

        .program-announcement-arrow--image {
            width: 100%;
        }
    }

    // Facets
    & {
        // Unary
        &.program-announcement-arrow__unary {
            .program-announcement-arrow--col {
                &.program-announcement-arrow--col__info {
                    // silence
                }
            }
        }

        // Dual
        &.program-announcement-arrow__dual {
            .program-announcement-arrow--col {
                position: static;

                &.program-announcement-arrow--col__info {
                    margin-left: $sz--dual-lateral-margin;
                }
            }
        }

        // Dual & `arrow-up-left`
        &.program-announcement-arrow__dual {
            .program-announcement-arrow--arrow-positioner {
                position: static;
            }

            .program-announcement-arrow--image {
                width: 100%;
            }
        }

        // Straight right
        &.program-announcement-arrow__dual.program-announcement-arrow__arrow-straight-right {
            padding-bottom: $straight-right-arrow-height / 2;

            .program-announcement-arrow--row {
                position: relative;
            }

            .program-announcement-arrow--arrow-positioner {
                position: absolute;
                top: 50%;
                left: 10%;
                transform: translate(0, -50%);
            }

            .program-announcement-arrow--image {
                height: $straight-right-arrow-height;
            }
        }

        &.program-announcement-arrow__dual.program-announcement-arrow__arrow-down-right {
            .program-announcement-arrow--image {
                height: $down-right-arrow-height;
            }
        }

        // Dual & `arrow-up-left`
        &.program-announcement-arrow__dual.program-announcement-arrow__arrow-up-left {
            padding-bottom: 44px;

            .program-announcement-arrow--arrow-positioner {
                position: fixed;
            }

            .program-announcement-arrow--info {
                // This is the continuation of the arrow body throughout the length of the info text
                &::after {
                    content: '';
                    position: absolute;
                    background-color: $c--monochrome-white;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 44px;
                    margin-top: 24px;
                    transform: rotateZ(1turn);
                }
            }

            .program-announcement-arrow--row {
                position: relative;
            }

            .program-announcement-arrow--arrow-positioner {
                position: absolute;
                bottom: 0;
                left: 0;
                width: $up-left-arrow-width;
            }

            .program-announcement-arrow--image {
                width: 100%;
                transform: translate(0, 49px);
            }
        }

        &.program-announcement-arrow__is-visible {
            .program-announcement-arrow--info,
            .program-announcement-arrow--arrow-positioner {
                opacity: 1;
            }
        }
    }

    // States
}
