.dual-display {
    // Vars

    // Support

    // Module
    & {
        flex: 1 0 100%;
        height: 100%;

        @include mq('<=wuxga1') {
            &::after {
                @include display-warning;
            }
        }
    }

    // Facets
    & {
        // Overview
        & {
            .dual-display--col.dual-display--col__daily-program-overview {
                @include grid--span((ratio: 1 / 1));
            }
        }

        // Highlight & Announcement
        & {
            .dual-display--row.dual-display--row__highlights,
            .dual-display--row.dual-display--row__announcements {
                @include mq('wuxga2') {
                    flex-flow: row nowrap;
                    justify-content: space-between;
                }
            }

            .dual-display--col.dual-display--col__daily-program-list {
                @include grid--span((ratio: 1 / 4));

                @include mq('wuxga2') {
                    flex: 0 0 $daily-program-overview--event-list-item-width-wuxga2;
                }
            }
        }

        // Highlight
        & {
            .dual-display--col.dual-display--col__program-highlight-list {
                @include grid--span((ratio: 20 / 26));

                @include mq('wuxga2') {
                    flex: 0 1 100%;
                }
            }
        }

        // Announcement
        & {
            .dual-display--col.dual-display--col__program-announcement-list {
                @include grid--span((ratio: 20 / 26));
            }
        }
    }

    // States
}
