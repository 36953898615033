.intro {
    // Vars

    // Support

    // Module
    & {
        padding: 32px;

        .intro--title {
            @include type--h1;
        }

        .intro--description {
            @include type--h2;
        }

        .intro--list {
            padding-top: 16px;
        }

        .intro--link {
            color: $c--monochrome-white;
            text-decoration: underline;

            @include type--h3;
        }
    }

    // Facets

    // States
}
