/// Font weights used throughout the project
///
$fw--normal: 400;
$fw--heavy: 900;

/// The project's font families
///
$ff--base: 'Areal';

$ff--base-regular-filename: 'areal-bl-book-web';
$ff--base-heavy-filename: 'areal-bl-black-web';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial;
// stylelint-enable value-keyword-case
