.event-list-item {
    // Vars

    // Support

    // Module
    & {
        .event-list-item--header {
            //
        }

        .event-list-item--body {
            padding-left: 32px;
        }

        .event-list-item--time {
            @include ff--base-heavy;
            font-size: 5.8rem;
            line-height: 1.21;
        }

        .event-list-item--type {
            @include type--daily-program;
            color: $c--monochrome-brown-gray;
        }

        .event-list-item--title {
            @include type--daily-program;
        }

        .event-list-item--location {
            @include type--daily-program;
            color: $c--monochrome-brown-gray;
        }
    }

    // Facets
    & {
        &.event-list-item__is-hidden {
            opacity: 0;
        }
    }

    // States
}
