/// Web-static folder
///
$web-static: '../..';

/// BEM options
///
$bem-element-separator: '--';
$bem-modifier-separator: '__';

/// Media queries and breakpoints
/// See: `util/mq.scss`

/// Colors
/// See: `util/colors.scss`

/// Layers (z-index)
///
$layers: (
    content: 0,
    animation-mask: 1,
);

/// Fonts
///
$fz--medium: 1.6rem;
$fz--small: 1.4rem;
$fz--x-small: 1.2rem;

$lh--medium: 1.2;
$lh--small: 1.25;
$lh--x-small: 1.3;

/// Sizes & spacing
///
$sz--list-icon-width: 12px;
$sz--list-icon-gutter: 6px;
$sz--list-padding: ($sz--list-icon-width + $sz--list-icon-gutter);
$sz--list-vertical-spacing: 8px;

/// Transitions & animations
///
$trs--base-speed: 200ms;
$trs--base-fn: ease;
$trs--base: $trs--base-speed $trs--base-fn;

$trs--highlights-speed: 1000ms;
$trs--highlights-fn: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$trs--highlights: $trs--highlights-speed $trs--highlights-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform;
$trs--common-props: transition-props($trs--base, $trs--common-property-list...);

/// Border radius
///
$sz--border-radius-base: 0;
$sz--border-width-base: 2px;

/// Daily program overview
///
$daily-program-overview--event-list-item-width-wuxga2: 832px;

/// Modular scale of title sizes
///
$modular-scale--title-sizes: (
    10rem,
    12rem,
    14.4rem,
    17.28rem,
    20.736rem,
    24.883rem,
    29.86rem,
    35.832rem,
    42.998rem,
    51.598rem,
    61.917rem,
    74.301rem,
    89.161rem,
    106.993rem,
    128.392rem,
    154.07rem,
    184.884rem,
);
$sz--big-titles-lh: 0.96;
$sz--dual-lateral-margin: 1190px;
