.program-highlight-list {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 24px 0;
    }

    // Facets

    // States
}
