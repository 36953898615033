@mixin create-modular-scale($scale, $lateral-alignement: 'left') {
    @for $i from 1 through length($scale) {
        &[data-title-size='#{$i}'] {
            font-size: nth($scale, $i);
            // fix the big font indentation

            @if $lateral-alignement == 'left' {
                transform: translate($i * -0.07%, 0);
            } @else {
                transform: translate($i * 0.07%, 0);
            }
        }
    }
}
