.unary-display {
    // Vars

    // Support

    // Module
    & {
        flex: 1 0 100%;
        height: 100%;

        @include mq(('<=xxl', '>wuxga1')) {
            &::after {
                @include display-warning;
            }
        }
    }

    // Facets
    & {
        // Overview
        & {
            .unary-display--col.unary-display--col__daily-program-overview {
                @include grid--span((ratio: 1 / 1));
            }
        }

        // Highlight & Announcement
        & {
            .unary-display--col.unary-display--col__program-highlight-list,
            .unary-display--col.unary-display--col__program-announcement-list {
                @include grid--span((ratio: 1 / 1));
            }
        }
    }

    // States
}
