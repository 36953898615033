.skel {
    // vars
    //

    // base
    & {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .skel--main {
            display: flex;
            flex-flow: column nowrap;
            flex: 0 0 100%;
        }
    }
}
